import { GoogleTagManager } from '@next/third-parties/google';
import type { AppProps } from 'next/app';
import { Assistant } from 'next/font/google';
import { FC, useEffect, useState } from 'react';
import { Provider, connect } from 'react-redux';
import { agreeCookieNotice, setLanguage } from '../actions/baseActions';
import { useStore } from '../store';

import { setContext } from '@sentry/nextjs';
import useCookie from 'app/hooks/use-cookie';
import { cookieCountries } from 'app/modules/Constants';
import Helper from 'app/modules/Helper';
import { useSearchParams } from 'next/navigation';
import Script from 'next/script';
import '../styles/app.scss';
import '../styles/responsive.scss';
import '../styles/variables.scss';

const assistant = Assistant({
	subsets: ['latin-ext'],
	display: 'swap',
	weight: ['400', '500', '600', '700', '800'],
	variable: '--font-assistant',
});

export const initializeConsent = () => {
	if (typeof window !== 'undefined') {
		window.dataLayer = window.dataLayer || [];
		window.gtag =
			window.gtag ||
			function () {
				// eslint-disable-next-line prefer-rest-params
				(window.dataLayer = window.dataLayer || []).push(arguments);
			};

		window.gtag('consent', 'default', {
			functionality_storage: 'denied',
			security_storage: 'granted',
			ad_personalization: 'denied',
			ad_storage: 'denied',
			ad_user_data: 'denied',
			analytics_storage: 'denied',
			personalization_storage: 'denied',
			region: cookieCountries,
		});

		window.gtag('set', 'ads_data_redaction', true);
		window.gtag('set', 'url_passthrough', false);
	}
};

const App: FC<AppProps> = ({ Component, pageProps }) => {
	const [hydrated, setHydrated] = useState(false);
	const [enableA11yScript, setEnableA11yScript] = useState(false);
	const [showCookiebotScript, setShowCookiebotScript] = useState(false);
	const [isSiteLoaded, setIsSiteLoaded] = useState(false);
	const [clarityUserId] = useCookie('_clck');
	const [userIdAvailable, setUserIdAvailable] = useState(false);

	const store = useStore(pageProps.initialReduxState);
	const params = useSearchParams();
	const lng = params?.get('lng') as string;

	if (lng && typeof document !== 'undefined') {
		document.documentElement.lang = lng;

		if (Helper.isRtlLanguage(lng)) {
			document.body.classList.add('rtl');
		}

		const isHebrew = lng === 'he';
		if (isHebrew && !enableA11yScript) {
			setEnableA11yScript(true);
		}
	}

	useEffect(() => {
		if (clarityUserId && !userIdAvailable) {
			setUserIdAvailable(true);
			setContext('Clarity User', {
				id: clarityUserId,
			});
		}
	}, [clarityUserId]);

	useEffect(() => {
		let agreeCookieNoticeValue;
		try {
			agreeCookieNoticeValue = localStorage.getItem('cookieNotice');
			if (agreeCookieNoticeValue) {
				store.dispatch(agreeCookieNotice());
			}
		} catch (error) {
			console.error('Failed to read from localStorage, fallback to default value:', error);
		}
		initializeConsent();
		setHydrated(true);
	}, []);

	useEffect(() => {
		if (typeof window !== 'undefined') {
			setShowCookiebotScript(!window.location.pathname.includes('/passthru'));
		}
		if (!isSiteLoaded) {
			window.addEventListener('load', () => {
				setIsSiteLoaded(true);
			});
		}
	}, []);

	if (!hydrated) {
		// Returns null on first render, so the client and server match
		return null;
	}

	const renderGoogleAds = () => {
		return (
			<Script
				id="googleads"
				data-ad-client="ca-pub-2732183997877262"
				async
				src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"
			/>
		);
	};

	return (
		<>
			{isSiteLoaded && (
				<>
					{renderGoogleAds()}
					{enableA11yScript && (
						<Script strategy="afterInteractive" src="https://cdn.userway.org/widget.js" data-account="ckynQOSB8r" />
					)}
					{showCookiebotScript && (
						<Script
							strategy="lazyOnload"
							id="Cookiebot"
							src="https://consent.cookiebot.com/uc.js"
							data-cbid="696b4af8-081c-41e4-a5c7-ec156c9c9878"
							data-blockingmode="auto"
							type="text/javascript"
						/>
					)}
					<GoogleTagManager gtmId="GTM-PSL3ZCK" />
				</>
			)}
			<main className={assistant.variable}>
				<Component {...pageProps} />
			</main>
		</>
	);
};

const ConnectedMyApp = connect(null, { setLanguage, agreeCookieNotice })(App);

function AppWithProvider({ Component, pageProps, router }: AppProps) {
	const store = useStore(pageProps.initialReduxState);
	// const { growthbook, callback: growthbookCallback } = useGrowthbook();
	// store.dispatch(setGrowthbookCallback(growthbookCallback));

	return (
		// <GrowthBookProvider growthbook={growthbook}>
		<Provider store={store}>
			<ConnectedMyApp Component={Component} pageProps={pageProps} router={router} />
		</Provider>
		// </GrowthBookProvider>
	);
}

export default AppWithProvider;
