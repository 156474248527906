import { Locale, ar, de, enGB, es, fr, he, it, ja, pt } from 'date-fns/locale';

export const localeMap: Record<string, Locale> = {
	ar,
	'en-GB': enGB,
	jp: ja,
	pt,
	es,
	de,
	fr,
	it,
	he,
};

export const DEFAULT_LANGUAGE = 'en';

export const cookieCountries = ['FR', 'MD'];

export const DEFAULT_LOCALE = 'en-GB';

export enum TripType {
	ROUND_TRIP = 'round-trip',
	ONE_WAY = 'one-way',
}

export type ItemType = {
	label: string;
	value: string;
	icon?: string;
};

export const tripTypes: ItemType[] = [
	{
		label: 'keywords.roundTrip',
		value: 'round-trip',
	},
	{
		label: 'keywords.oneWay',
		value: 'one-way',
	},
];
export const pricingTypes = [
	{
		label: 'keywords.economy',
		value: 'economy',
	},
	{
		label: 'keywords.business',
		value: 'business',
	},
	{
		label: 'keywords.first',
		value: 'first',
	},
	{
		label: 'keywords.premium',
		value: 'premium',
	},
];

export const roomTypes: ItemType[] = [
	{
		label: 'main.hotels.onePerson',
		value: 'one-person',
		icon: 'icon-one-person',
	},
	{
		label: 'main.hotels.twoPerson',
		value: 'two-person',
		icon: 'icon-two-persons',
	},
	{
		label: 'main.hotels.family',
		value: 'family',
		icon: 'icon-family',
	},
];

export const childrenAges = [
	{ value: 0, label: 'Under 1' },
	{ value: 1, label: '1' },
	{ value: 2, label: '2' },
	{ value: 3, label: '3' },
	{ value: 4, label: '4' },
	{ value: 5, label: '5' },
	{ value: 6, label: '6' },
	{ value: 7, label: '7' },
	{ value: 8, label: '8' },
	{ value: 9, label: '9' },
	{ value: 10, label: '10' },
	{ value: 11, label: '11' },
	{ value: 12, label: '12' },
	{ value: 13, label: '13' },
	{ value: 14, label: '14' },
	{ value: 15, label: '15' },
	{ value: 16, label: '16' },
	{ value: 17, label: '17' },
];
